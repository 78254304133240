import { useRouter } from 'next/router';
import Button from 'barn/components/Button';
import { Stack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';

type Props = {
  heading: string;
  index: number;
  subHeading?: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  link: string;
  handleComplete?: (index: number) => void;
  primaryButtonText: string;
  secondaryButtonText?: string;
  openLinkInNewTab?: boolean;
};

export const StepDetail = ({
  index,
  heading,
  subHeading = null,
  children,
  isLoading,
  handleComplete,
  link,
  primaryButtonText,
  secondaryButtonText,

  openLinkInNewTab = true,
}: Props) => {
  const router = useRouter();

  const handleNaviate = () => {
    if (openLinkInNewTab) {
      window.open(link, '_blank');
    } else {
      router.push(link);
    }
  };

  return (
    <VStack gap={5} testHandle={`step-${index}`}>
      <VStack gap={2}>
        <Text tag='h3' size={5} weight={700} variant='primary'>
          {heading}
        </Text>

        <Text size={2} variant='secondary'>
          {subHeading}
        </Text>
      </VStack>

      {children}

      <Stack direction='responsive' gap={4}>
        <Button
          testHandle='getting-started-primary-button'
          onClick={handleNaviate}
        >
          {primaryButtonText}
        </Button>

        {secondaryButtonText && (
          <Button
            appearance='secondary'
            isLoading={isLoading}
            testHandle='getting-started-secondary-button'
            onClick={() => handleComplete(index)}
          >
            {secondaryButtonText}
          </Button>
        )}
      </Stack>
    </VStack>
  );
};
