import Modal from 'barn/components/Modal/Modal';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import Input from 'barn/components/form/input';
import Textarea from 'barn/components/form/textarea';
import noop from 'lodash.noop';
import { get, put } from 'src/lib/api/axios';
import styled from 'styled-components';
import Button from 'barn/components/Button';
import { useEffect, useState } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import Spinner from 'barn/components/Spinner';
import { useRouter } from 'next/router';
import { startConversationWithUser } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import { PHONE_NUMBER_VERIFICATION_STATUS } from 'src/modules/home/models';

const StyledForm = styled.form`
  width: 100%;

  div {
    width: 100%;
  }
`;

export const NumberVerificationModal = ({
  isNumberVerificationModalOpen,
  setNumberVerificationModal,
}) => {
  const { t } = useTranslation('home');

  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    domain: '',
    billing_address: {
      address1: '',
      address2: '',
      city: '',
      province: '',
      country: '',
      zip: '',
    },
  });

  const {
    actions: { showError: showErrorToast, showDone: showSuccessToast },
  } = useGlobalState('saveToast');
  const {
    actions: { setBannerState },
  } = useGlobalState('home');

  const router = useRouter();

  const fetchUserDetails = async () => {
    try {
      const response: {
        data;
      } = await get('/onboarding/sms/store_info/');
      const {
        name,
        email,
        phone,
        first_name,
        last_name,
        domain,
        billing_address: { address1, address2, city, province, country, zip },
      } = response.data;
      setUserDetails({
        name,
        email,
        phone,
        firstName: first_name,
        lastName: last_name,
        domain,
        billing_address: { address1, address2, city, province, country, zip },
      });
      setIsLoading(false);
    } catch (error) {
      showErrorToast(t('details_verification_form.unable_fetch_user_details'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await put('/tasks/', {
        task_type: 'number_verification',
        status: 'pending',
      });
      showSuccessToast(t('details_verification_form.success_toast_message'));
      setNumberVerificationModal(false);
      setBannerState({
        numberVerificationStatus: PHONE_NUMBER_VERIFICATION_STATUS.PENDING,
      });
    } catch (error) {
      setNumberVerificationModal(false);
      showErrorToast(t('details_verification_form.unsuccessful_toast_message'));
    }
  };

  const handleOnContactSupport = () => {
    startConversationWithUser(
      t('details_verification_form.support_help_message'),
    );
    setNumberVerificationModal(false);
  };

  if (isLoading)
    return (
      <Modal isOpen>
        <Spinner currentColor centered />
      </Modal>
    );

  return (
    <Modal
      showCloseButton={false}
      onRequestClose={() => setNumberVerificationModal(false)}
      isOpen={isNumberVerificationModalOpen}
    >
      <VStack align='flex-start' gap={4}>
        <VStack gap={3}>
          <Text tag='h2' size={3} weight='bold'>
            {t('details_verification_form.heading')}
          </Text>
          <Text maxWidth='600px'>
            {t('details_verification_form.subheading')}
          </Text>
        </VStack>

        <StyledForm>
          <VStack gap={4}>
            <VStack gap={4}>
              <HStack gap={4} justify='space-between'>
                <div>
                  <Input
                    fullWidth
                    label={t('details_verification_form.business_name')}
                    name='business-name'
                    disabled
                    value={userDetails.name}
                  />
                </div>
                <div>
                  <Input
                    fullWidth
                    label={t(
                      'details_verification_form.busines_contact_first_name',
                    )}
                    name='business-contact-first-name'
                    disabled
                    value={userDetails.firstName}
                  />
                </div>
              </HStack>

              <HStack justify='space-between' gap={4}>
                <div>
                  <Input
                    label={t('details_verification_form.busines_contact_email')}
                    name='business-contact-email'
                    disabled
                    value={userDetails.email}
                  />
                </div>
                <div>
                  <Input
                    label={t(
                      'details_verification_form.busines_contact_last_name',
                    )}
                    name='business-contact-last-name'
                    disabled
                    value={userDetails.lastName}
                  />
                </div>
              </HStack>

              <div>
                <Input
                  fullWidth
                  label={t('details_verification_form.website')}
                  name='corporate-website'
                  disabled
                  value={userDetails.domain}
                />
              </div>

              <HStack justify='space-between' gap={4}>
                <div>
                  <Textarea
                    fullWidth
                    disabled
                    withEmoji={false}
                    label={t('details_verification_form.business_address_1')}
                    name='business-address-1'
                    value={userDetails.billing_address.address1}
                    onChange={noop}
                  />
                </div>
                <div>
                  <VStack gap={4}>
                    <Input
                      label={t(
                        'details_verification_form.business_phone_number',
                      )}
                      name='business-contact-phone-number'
                      disabled
                      value={userDetails.phone}
                    />
                    <Input
                      label={t('details_verification_form.state')}
                      name='state'
                      disabled
                      value={userDetails.billing_address.province}
                    />
                  </VStack>
                </div>
              </HStack>

              <HStack gap={4} justify='space-between'>
                <div>
                  <Textarea
                    fullWidth
                    disabled
                    withEmoji={false}
                    label={t('details_verification_form.business_address_2')}
                    name='business-address-2'
                    value={userDetails.billing_address.address2}
                    onChange={noop}
                  />
                </div>
                <div>
                  <VStack gap={4}>
                    <Input
                      label={t('details_verification_form.city')}
                      name='city'
                      disabled
                      value={userDetails.billing_address.city}
                    />
                    <Input
                      label={t('details_verification_form.zip_code')}
                      name='zip'
                      disabled
                      value={userDetails.billing_address.zip}
                    />
                  </VStack>
                </div>
              </HStack>
            </VStack>
            <HStack justify='flex-end' alignSelf='flex-end' gap={4}>
              <Button onClick={handleOnContactSupport} appearance='secondary'>
                {t('details_verification_form.contact_support')}
              </Button>
              <Button type='submit' onClick={handleSubmit}>
                {t('details_verification_form.submit_for_verification')}
              </Button>
            </HStack>
          </VStack>
        </StyledForm>
      </VStack>
    </Modal>
  );
};
