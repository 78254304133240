import tokens from 'barn/tokens';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const GettingStartedProgress = styled(motion.div)`
  margin: ${tokens.space.margin[6]} 0;
  overflow: hidden;
  position: relative;
`;

const Line = styled.div`
  margin: ${tokens.space.margin[6]} 0;
  border-top: 1px solid ${({ theme }) => theme.home.line.borderColor};
`;

export const List = styled.ul`
  padding-left: ${tokens.space.padding[5]};
  list-style-type: '-';
  > li {
    line-height: 1.8rem;
    padding-left: ${tokens.space.padding[2]};
  }
`;

export const Screenshot = styled.img`
  max-width: 600px;
`;

const ResponsiveSeparator = styled.div`
  display: block;
  background: ${tokens.colors.greys[5]};
  flex: 0 1 1px;
  width: 100%;
`;

const OrderedList = styled.ol`
  padding-left: ${tokens.space.padding[6]};
`;

const OrderedListItem = styled.li<{ isActive: boolean }>`
  line-height: 2.5;
  font-weight: ${props => (props.isActive ? 700 : 'normal')};
`;

export default {
  GettingStartedProgress,
  ResponsiveSeparator,
  Line,
  OrderedList,
  OrderedListItem,
};
