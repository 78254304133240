import { differenceInDays } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import InfoRounded from 'barn/icons/InfoRounded';
import { Tooltip } from 'barn/components/Tooltip';
import useAuth from 'src/hooks/use-auth';
import Styled from '../styled';

const CustomLegend = ({ payload }) => {
  const {
    user: {
      website: { created_at: createdAt },
    },
  } = useAuth();
  const { t } = useTranslation('home');

  const infoText = t('subscriber_gain.tooltip');

  const isStoreLessThan30DaysOld =
    differenceInDays(
      Date.now(),
      new Date(createdAt.replace(' ', 'T')).getTime(),
    ) < 30;

  return (
    <Styled.Legend>
      {payload.map((entry, index) => (
        <VStack align='center' gap={2} key={entry.value}>
          <HStack gap={2} align='center' key={`item-${index}`}>
            <Styled.LegendIcon />

            <Text variant='primary'>{entry.value}</Text>

            {!isStoreLessThan30DaysOld && (
              <Tooltip placement='bottom' tip={infoText}>
                <InfoRounded size={16} />
              </Tooltip>
            )}
          </HStack>

          {isStoreLessThan30DaysOld && (
            <HStack align='center' gap={2}>
              <InfoRounded size={16} />
              <Text>{infoText}</Text>
            </HStack>
          )}
        </VStack>
      ))}
    </Styled.Legend>
  );
};

export default CustomLegend;
