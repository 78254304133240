import colors from 'barn/tokens/colors';
import { v1 as uuidv1 } from 'uuid';

const BarGraphIcon = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg
      style={style}
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id={`maskBackInStock${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='13'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.4 0H15.2C15.6416 0 16 0.3584 16 0.8V12C16 12.4416 15.6416 12.8 15.2 12.8H0.8C0.3584 12.8 0 12.4416 0 12V7.2C0 6.7584 0.3584 6.4 0.8 6.4H4.8V4C4.8 3.5584 5.1584 3.2 5.6 3.2H9.6V0.8C9.6 0.3584 9.9584 0 10.4 0ZM14.4 11.2H11.2V1.6H14.4V11.2ZM6.4 11.2H9.6V4.8H6.4V11.2ZM4.8 11.2H1.6V8H4.8V11.2Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskBackInStock${id})`}>
        <rect width='17' height='14' fill={color} className='iconColor' />
        <rect
          width='17'
          height='14'
          fill={color}
          className='iconColor'
          fillOpacity='0.25'
        />
      </g>
    </svg>
  );
};

export default BarGraphIcon;
