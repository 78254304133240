import { Text } from 'barn/components/Text';
import { VStack } from 'barn/components/Stack';
import tokens from 'barn/tokens';
import { useTranslation } from 'next-i18next';

const Welcome = () => {
  const { t } = useTranslation('home');

  return (
    <div
      style={{
        paddingTop: tokens.space.padding[7],
      }}
    >
      <VStack gap={1}>
        <Text tag='h1' size={6} weight='500' variant='primary'>
          {t('hi')}
        </Text>
        <Text tag='p' size={4} variant='secondary'>
          {t('welcome')}
        </Text>
      </VStack>
    </div>
  );
};

export default Welcome;
