import { format } from 'date-fns';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import Styled from '../styled';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload) return null;

  const {
    payload: { count },
  } = payload[0];

  return (
    <Styled.Tooltip role='tooltip'>
      <HStack gap={3}>
        <Styled.TooltipLine />
        <VStack gap={3}>
          <VStack>
            <Text tag='h4' size={2} weight={600} variant='primary'>
              Subscriber Growth
            </Text>
            <Text variant='primary'>
              {format(new Date(label), 'eee, do MMM u')}
            </Text>
          </VStack>
          <Text size={3} weight={600} variant='primary'>
            {count >= 0 ? '+' : ''}
            {count}
          </Text>
        </VStack>
      </HStack>
    </Styled.Tooltip>
  );
};

export default CustomTooltip;
