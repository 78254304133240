import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

type HyperLinkProps = {
  fontSize: string;
};

const Default = styled.a<HyperLinkProps>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${props => props.fontSize};
  font-weight: normal;
  color: ${({ theme }) => theme.hyperlink.default.color};

  & .iconColor {
    fill: ${({ theme }) => theme.hyperlink.default.color};
  }

  &:active {
    color: ${({ theme }) => theme.hyperlink.default.active.color};

    & .iconColor {
      color: ${({ theme }) => theme.hyperlink.default.active.color};
    }
  }
`;

const Secondary = styled(Default)`
  color: ${({ theme }) => theme.hyperlink.secondary.color};
  text-decoration: underline;
  & .iconColor {
    fill: ${({ theme }) => theme.hyperlink.secondary.color};
  }

  &:active {
    color: ${({ theme }) => theme.hyperlink.secondary.active.color};

    & .iconColor {
      color: ${({ theme }) => theme.hyperlink.secondary.active.color};
    }
  }
`;

const Button = styled.a<HyperLinkProps>`
  display: inline-flex;
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[5]};
  font-size: ${props => props.fontSize};
  border-radius: ${tokens.radii[4]};
  background-color: ${({ theme }) => theme.hyperlink.button.bg};
  border-color: ${({ theme }) => theme.hyperlink.button.borderColor};
  color: ${({ theme }) => theme.hyperlink.button.color};
  font-weight: 600;
  transition: 0.25s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.hyperlink.button.hover.bg};
    border-color: ${({ theme }) => theme.hyperlink.button.hover.borderColor};
    box-shadow: ${({ theme }) => theme.hyperlink.button.hover.boxShadow};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background-color: ${({ theme }) =>
        theme.hyperlink.button.hover.disabled.bg};
      border-color: ${({ theme }) =>
        theme.hyperlink.button.hover.disabled.borderColor};
      box-shadow: none;
    }
  }
`;

const StyledAnchors = {
  default: Default,
  secondary: Secondary,
  button: Button,
};

type Props = {
  href?: string;
  onClick?: Function | null;
  target?: any;
  testHandle?: string | null;
  children: React.ReactNode;
  variant?: 'default' | 'secondary' | 'button';
  size?: string;
};

const hyperlinkFontSize = {
  sm: fontSizes[1],
  md: fontSizes[2],
};

const Hyperlink = ({
  href = '#',
  onClick = null,
  target = null,
  testHandle = null,
  variant = 'default',
  size = 'md',
  children,
}: Props) => {
  const StyledAnchor = StyledAnchors[variant];

  return (
    <StyledAnchor
      href={href}
      fontSize={hyperlinkFontSize[size]}
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      target={target || '_blank'}
      rel='noopener'
      data-test={testHandle}
    >
      {children}
    </StyledAnchor>
  );
};

export default Hyperlink;
