import colors from 'barn/tokens/colors';

const CheckCircle = ({ color = colors.greens[9], style = {} }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <path
        d='M14.7828 6.19878L8.5014 12.2291L5.47675 9.30184L4.78131 10.0204L7.86667 13.0065L8.4753 13.6404L8.84628 13.2843L15.4753 6.92016L14.7828 6.19878ZM19.629 9.66115C19.629 15.1108 15.3601 19.5 10.129 19.5C4.89789 19.5 0.629028 15.1108 0.629028 9.66115C0.629028 4.24761 4.73253 0.5 9.99997 0.5C15.2847 0.5 19.629 4.26386 19.629 9.66115Z'
        stroke={color}
      />
    </svg>
  );
};

export default CheckCircle;
