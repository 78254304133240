import styled from 'styled-components';
import tokens from 'barn/tokens';

export const StyledBanner = styled.article<{ category: string }>`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  border-radius: ${tokens.radii[4]};
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[6]};
  color: ${({ theme }) => theme.banners.color};
  ${({ category, theme }) => {
    return `
    border: 1px solid ${theme.banners[category].borderColor};
		background-color: ${theme.banners[category].bg};
    `;
  }}
`;
