import styled from 'styled-components';
import tokens from 'barn/tokens';

const StyledContent = styled.div`
  position: relative;
  border-radius: ${tokens.radii[5]};
  background: ${({ theme }) => theme.layout.container.bg};
`;

const Content: React.SFC = ({ children }) => (
  <StyledContent>{children}</StyledContent>
);

export default Content;
