import styled from 'styled-components';
import { sidebarWidth, responsiveSidebarWidth } from 'barn/tokens/layout';
import tokens from 'barn/tokens';
import { useGlobalState } from 'src/hooks/use-global-state';

const StyledWrapper = styled.div<{ isSidebarOpen: boolean }>`
  position: relative;
  padding: ${tokens.space.padding[7]};
  padding-top: 0;

  padding-left: calc(${sidebarWidth} + ${tokens.space.padding[7]});

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    padding-left: calc(${responsiveSidebarWidth} + ${tokens.space.padding[7]});
  }
`;

const Wrapper = ({ children }) => {
  const {
    state: { isSidebarOpen },
  } = useGlobalState('common');

  return (
    <StyledWrapper id='main-content' isSidebarOpen={isSidebarOpen}>
      {children}
    </StyledWrapper>
  );
};

export default Wrapper;
