import { useTranslation } from 'react-i18next';
import { Text } from 'barn/components/Text';
import useAuth from 'src/hooks/use-auth';
import { StepProp } from './index';
import { StepDetail } from './StepDetail';

export const Optin = (props: StepProp) => {
  const {
    user: {
      website: { subdomain },
    },
  } = useAuth();
  const { t } = useTranslation('home');
  const link = `https://${subdomain}.myshopify.com/admin/settings/checkout#MarketingConsentOptionsSection`;

  return (
    <StepDetail
      heading={t('setup_card.collect_subscribers.title')}
      subHeading={t('setup_card.collect_subscribers.subheading')}
      link={link}
      primaryButtonText={t('setup_card.collect_subscribers.enable')}
      secondaryButtonText={t('setup_card.collect_subscribers.dismiss')}
      {...props}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video width='600px' height='400' controls autoPlay>
        <source src='/static/sms_marketing.mp4' type='video/mp4' />
        Upgrade your browser to see the video
      </video>

      <Text size={2} maxWidth='70ch'>
        {t('setup_card.collect_subscribers.description')}
      </Text>
    </StepDetail>
  );
};
