import { useContext, useEffect, useRef, CSSProperties } from 'react';
import { ThemeContext } from 'styled-components';
import { motion, MotionProps } from 'framer-motion';
import { useRouter } from 'next/router';
import Button from 'barn/components/Button';
import { useGlobalState } from 'src/hooks/use-global-state';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import Coupons from 'barn/icons/Coupons';
import SwitchIcon from 'barn/icons/Switch';
import InfoRoundedFilled from 'barn/icons/InfoRoundedFilled';
import Alert from 'barn/icons/Alert';
import space from 'barn/tokens/space';
import CheckCircleIcon from 'barn/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { startConversationWithUser } from 'src/lib/utils';
import { gotoNext } from 'src/lib/goto-next';
import { logErrorToSentry } from 'src/lib/debug-utils';
import {
  PHONE_NUMBER_VERIFICATION_STATUS,
  SYNC_SUBSCRIBER_STATUS,
} from 'src/modules/home/models';
import { StyledBanner } from './styled';
import { ButtonType, BANNER_BUTTON_ACTION } from './model';
import { NumberVerificationModal } from './NumberVerificationModal';

const iconsMapping = {
  promo: Coupons,
  info: InfoRoundedFilled,
  switch: SwitchIcon,
  success: CheckCircleIcon,
  warn: Alert,
  danger: Alert,
};

type Props = {
  motionVariants: {
    initial: MotionProps['initial'];
    animate: MotionProps['animate'];
  };
  style?: CSSProperties;
};

export const Banner = ({
  motionVariants: { initial, animate },
  style,
}: Props) => {
  const {
    state: { banners, isFetching, isAnimationDone },
    actions: { setState },
  } = useGlobalState('banners');
  const {
    state: {
      numberVerification: { isNumberVerificationModalOpen },
    },
    actions: {
      syncSubscribers,
      setSyncSubscribersStatus,
      setNumberVerificationModal,
      setPhoneNumberVerificationStatus,
      setBannerState,
    },
  } = useGlobalState('home');

  const router = useRouter();
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation('home');

  /* eslint-disable */
  const clickHandler = ({
    handler,
    action,
    target,
    url,
    buttonAction,
  }: ButtonType) => {
    if (buttonAction) {
      switch (buttonAction) {
        case BANNER_BUTTON_ACTION.START_SYNC_SUBSCRIBERS:
          setPhoneNumberVerificationStatus({
            numberVerificationStatus:
              PHONE_NUMBER_VERIFICATION_STATUS.DISMISSED,
            syncSubscriberStatus: SYNC_SUBSCRIBER_STATUS.IN_PROGRESS,
          });
          syncSubscribers();
          setBannerState({
            numberVerificationStatus:
              PHONE_NUMBER_VERIFICATION_STATUS.DISMISSED,
          });
          break;
        case BANNER_BUTTON_ACTION.DISMISS_SYNC_SUBSCRIBERS:
          setSyncSubscribersStatus({
            status: SYNC_SUBSCRIBER_STATUS.DISMISSED,
          });
          break;
        case BANNER_BUTTON_ACTION.OPEN_REVIEW_DETAILS_MODAL:
          setNumberVerificationModal(true);
          break;
        case BANNER_BUTTON_ACTION.DISMISS_NUMBER_VERIFICATION:
          setPhoneNumberVerificationStatus({
            numberVerificationStatus:
              PHONE_NUMBER_VERIFICATION_STATUS.DISMISSED,
            syncSubscriberStatus: SYNC_SUBSCRIBER_STATUS.NOT_STARTED,
          });
          break;
        case BANNER_BUTTON_ACTION.CONTACT_SUPPORT:
          startConversationWithUser(
            t('details_verification_form.in_progress_support_help_message'),
          );
          break;
      }
      return;
    }

    if (handler === 'js') eval(action);
    else if (target === '_blank') {
      window.open(url);
    } else {
      try {
        const nextURL = new URL(url);
        const next = nextURL.searchParams.get('next');
        if (next) {
          gotoNext(next);
        } else {
          router.push(url);
        }
      } catch (error) {
        logErrorToSentry({
          error,
        });
      }
    }
  };

  const isABannerRendered = useRef(false);

  useEffect(() => {
    /**
     * Update isAnimationDone after running the animation once
     * since repeated animations on multiple pages is not good ux
     *
     * Wait till isFetching is false otherwise the state will
     * get updated when Banner returns null.
     *
     * Make sure that a banner has been rendered before setting.
     */
    if (!isAnimationDone && !isFetching && isABannerRendered.current)
      setState({ isAnimationDone: true });
  }, [isFetching]);

  /**
   * Return null if there's no applicable banner to be displayed
   */
  if (
    isFetching ||
    banners.length === 0 ||
    !banners.find(banner => banner.paths.includes(router.pathname))
  )
    return null;

  if (isNumberVerificationModalOpen)
    return (
      <NumberVerificationModal
        isNumberVerificationModalOpen={isNumberVerificationModalOpen}
        setNumberVerificationModal={setNumberVerificationModal}
      />
    );

  return (
    <motion.section
      style={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: space.margin[5],
        ...style,
      }}
      initial={!isAnimationDone && initial}
      animate={animate}
    >
      {banners.map(({ title, icon, category, description, buttons, paths }) => {
        if (!paths.includes(router.pathname)) return null;

        isABannerRendered.current = true;

        const Icon = iconsMapping[icon];

        return (
          <StyledBanner key={title} category={category}>
            <HStack gap={4} align='center'>
              <figure
                style={{ alignSelf: description ? 'flex-start' : 'center' }}
              >
                <Icon
                  style={{ height: '30', width: '30' }}
                  color={themeContext.banners[category].icon.color}
                />
              </figure>

              <VStack gap={1}>
                <Text size={2} weight={600} tag='h3'>
                  {title}
                </Text>
                {description && <Text>{description}</Text>}
              </VStack>
            </HStack>

            <HStack gap={5}>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  onClick={() => clickHandler(button)}
                  variant={button.variant}
                  appearance={button.appearance}
                  testHandle='banner-cta-btn'
                  disabled={button.disabled}
                  isLoading={button.isLoading}
                >
                  {button.text}
                </Button>
              ))}
            </HStack>
          </StyledBanner>
        );
      })}
    </motion.section>
  );
};
