import colors from 'barn/tokens/colors';

const LineGraphIcon = ({ color = colors.greys[2], style = {} }) => {
  return (
    <svg
      style={style}
      width='18'
      height='15'
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.414348 13.5315C0.155591 13.8549 0.208032 14.3269 0.531479 14.5857C0.854925 14.8444 1.32689 14.792 1.58565 14.4685L0.414348 13.5315ZM15.6431 2.38587C15.8562 2.03069 15.7411 1.56999 15.3859 1.35688C15.0307 1.14377 14.57 1.25894 14.3569 1.61413L15.6431 2.38587ZM11.6195 6.87317L11.3823 7.58468L11.6195 6.87317ZM12.2064 6.65607L11.5632 6.2702L12.2064 6.65607ZM6.75071 12.3335L6.91341 11.6014L6.75071 12.3335ZM7.34947 11.7473L6.61403 11.8944L7.34947 11.7473ZM2.80532 11.7434L2.21967 11.2748L2.80532 11.7434ZM3.30422 11.5676L3.46692 10.8355L3.30422 11.5676ZM1.58565 14.4685L3.39097 12.2119L2.21967 11.2748L0.414348 13.5315L1.58565 14.4685ZM3.14152 12.2997L6.58801 13.0656L6.91341 11.6014L3.46692 10.8355L3.14152 12.2997ZM8.0849 11.6003L6.90441 5.69777L5.43354 5.99195L6.61403 11.8944L8.0849 11.6003ZM6.5802 5.98397L11.3823 7.58468L11.8567 6.16165L7.05455 4.56095L6.5802 5.98397ZM12.8495 7.04194L15.6431 2.38587L14.3569 1.61413L11.5632 6.2702L12.8495 7.04194ZM11.3823 7.58468C11.9381 7.76995 12.548 7.54433 12.8495 7.04194L11.5632 6.2702C11.6235 6.16972 11.7455 6.1246 11.8567 6.16165L11.3823 7.58468ZM6.90441 5.69777C6.94205 5.886 6.76231 6.04467 6.5802 5.98397L7.05455 4.56095C6.14402 4.25744 5.24531 5.05081 5.43354 5.99195L6.90441 5.69777ZM6.58801 13.0656C7.46991 13.2616 8.26208 12.4861 8.0849 11.6003L6.61403 11.8944C6.5786 11.7173 6.73703 11.5622 6.91341 11.6014L6.58801 13.0656ZM3.39097 12.2119C3.33139 12.2863 3.23463 12.3204 3.14152 12.2997L3.46692 10.8355C3.00139 10.732 2.51757 10.9024 2.21967 11.2748L3.39097 12.2119Z'
        fill={color}
        className='iconColor'
      />
      <path
        d='M12.4252 1.02756C12.0193 1.11021 11.7573 1.50625 11.8399 1.91213C11.9226 2.31802 12.3186 2.58005 12.7245 2.4974L12.4252 1.02756ZM15.5696 4.37983C15.6523 4.78571 16.0483 5.04775 16.4542 4.9651C16.8601 4.88245 17.1221 4.48641 17.0395 4.08053L15.5696 4.37983ZM15.5765 1.15125L15.7262 1.88616L15.5765 1.15125ZM15.6935 1.22948L14.9586 1.37913L15.6935 1.22948ZM12.7245 2.4974C13.7712 2.28426 15.3255 1.96776 15.7262 1.88616L15.4269 0.416329C15.0262 0.497927 13.4719 0.814424 12.4252 1.02756L12.7245 2.4974ZM14.9586 1.37913L15.5696 4.37983L17.0395 4.08053L16.4284 1.07983L14.9586 1.37913ZM15.7262 1.88616C15.3648 1.95975 15.0282 1.72123 14.9586 1.37913L16.4284 1.07983C16.3367 0.629496 15.8965 0.320704 15.4269 0.416329L15.7262 1.88616Z'
        fill={color}
        className='iconColor'
      />
    </svg>
  );
};

export default LineGraphIcon;
