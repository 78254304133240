import styled from 'styled-components';
import tokens from 'barn/tokens';

interface StyledPaneProps {
  noPadding: boolean;
  noPaddingLeftRight: boolean;
}
const StyledPane = styled.section<StyledPaneProps>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.pane.bg};
  border-radius: ${tokens.radii[5]};
  box-shadow: ${({ theme }) => theme.pane.boxShadow};

  > .titlebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.pane.titlebar.borderColor};
    padding: ${tokens.space.padding[6]};
  }

  > .content {
    padding: ${props => {
      if (props.noPadding) return 0;

      if (props.noPaddingLeftRight) return `${tokens.space.padding[6]} 0`;

      return tokens.space.padding[6];
    }};

    flex: 1;
  }
`;

interface Props {
  titleBarContent?: any;
  noPadding?: boolean;
  noPaddingLeftRight?: boolean;
  testHandle?: string | null;
  className?: string;
}
const Pane: React.FunctionComponent<Props> = ({
  titleBarContent,
  noPadding = false,
  noPaddingLeftRight = false,
  testHandle = null,
  className = '',
  children,
}) => (
  <StyledPane
    noPadding={noPadding}
    noPaddingLeftRight={noPaddingLeftRight}
    data-test={testHandle}
    className={className}
  >
    {titleBarContent && <div className='titlebar'>{titleBarContent}</div>}
    <div className='content'>{children}</div>
  </StyledPane>
);

export { Pane };
