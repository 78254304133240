import { useGlobalState } from 'src/hooks/use-global-state';
import { Privacy } from './Privacy';
import { TOS } from './TOS';
import { Optin } from './Optin';
import { ACR } from './ACR';

export type StepProp = {
  index: number;
  handleComplete: (index: number) => void;
  isLoading: boolean;
};

export const StepDetails = () => {
  const {
    state: { gettingStartedStep, isSettingGettingStarted: isLoading },
    actions: { setGettingStarted },
  } = useGlobalState('home');
  const {
    actions: { showError },
  } = useGlobalState('saveToast');

  const handleComplete = async (index: number) => {
    try {
      await setGettingStarted(index);
    } catch (error) {
      showError('Changes were not saved');
    }
  };

  const props: StepProp = {
    index: gettingStartedStep,
    handleComplete,
    isLoading,
  };

  switch (gettingStartedStep) {
    case 0:
      return <Privacy {...props} />;
    case 1:
      return <TOS {...props} />;
    case 2:
      return <Optin {...props} />;
    case 3:
      return <ACR {...props} />;
    default:
      return null;
  }
};
