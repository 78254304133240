import styled from 'styled-components';
import space from 'barn/tokens/space';
import { VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';

const List = styled.div`
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-gap: ${space.margin[10]};
  padding: ${space.padding[6]} 0px;
`;

type Stat = {
  label: string;
  value: string | React.ReactNode;
};
interface Props {
  data: Array<Stat>;
}

const Stats: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <List>
      {data.map((stat: Stat) => (
        <VStack gap={3} key={stat.label}>
          <Text size={5} weight={600} variant='primary'>
            {stat.value}
          </Text>
          <Text variant='secondary'>{stat.label}</Text>
        </VStack>
      ))}
    </List>
  );
};

export default Stats;
