import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { format } from 'date-fns';
import { Pane } from 'barn/components/Pane';
import { HStack, VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import { useGlobalState } from 'src/hooks/use-global-state';
import NumberFormat from 'src/components/NumberFormat';
import { DaterangeFilter, DaysInSeconds } from 'src/components/DaterangeFilter';
import { SingleSelect } from 'barn/components/Dropdown/SingleSelect';
import Button from 'barn/components/Button';
import LineGraphIcon from 'barn/icons/LineGraph';
import BarGraphIcon from 'barn/icons/BarGraph';
import { Tooltip } from 'barn/components/Tooltip';
import {
  ReportsGranularity,
  reportsGranularity,
} from 'src/components/ReportSaver/models';
import useAuth from 'src/hooks/use-auth';
import Spinner from 'barn/components/Spinner';
import Styled from './styled';
// import Locations from './Locations';
import SubscriberBarChart from './graph/SubscriberBarChart';
import SubscriberLineChart from './graph/SubscriberLineChart';

const SubscriberStats = () => {
  const [graphType, setGraphType] = useState<'bar' | 'line'>('bar');
  const { t } = useTranslation('home');

  const {
    state: {
      reports: {
        isFetching,
        totalSubsGained,
        filters: { timeFilter, granularity },
      },
    },
    actions,
  } = useGlobalState('home');
  const {
    user: {
      website: { created_at: createdAt },
    },
  } = useAuth();

  // timeFilter is [Date, Date, {value, label, type}], with [0] and [1], the start and end dates selected
  const firstDate =
    timeFilter[2].value === DaysInSeconds.ALL_TIME
      ? new Date(createdAt)
      : timeFilter[0];

  return (
    <VStack gap={4}>
      <HStack gap={3} align='center'>
        <Text size={4} variant='secondary'>
          {t('subscriber_gain.time_filter')}
        </Text>
        {isFetching ? (
          <Spinner small centered />
        ) : (
          <DaterangeFilter
            buttonSize='sm'
            state={timeFilter[2]}
            onChange={(...timeFilter) => {
              actions.updateFiltersAndFetchGrowthReport({ timeFilter });
            }}
            disableCustomRange
          />
        )}
      </HStack>

      <Styled.StatsWrapper>
        <Pane testHandle='home-subscriber-graph-card'>
          <VStack gap={4} height='100%'>
            <VStack>
              <HStack justify='space-between' align='flex-start'>
                <HStack align='baseline' gap={2}>
                  <Text tag='h2' size={3} weight={600} variant='primary'>
                    {t('subscriber_gain.heading')}
                  </Text>

                  <Styled.Separator />

                  <Text variant='secondary'>
                    {format(firstDate, 'd MMM yy')} -{' '}
                    {format(timeFilter[1], 'd MMM yy')}
                  </Text>

                  <Styled.Separator />

                  <Text variant='secondary'>
                    {timeFilter[2].label || 'Custom Date'}
                  </Text>
                </HStack>

                <HStack align='center' gap={4}>
                  <Tooltip
                    tip={t('subscriber_gain.line_chart_tooltip')}
                    placement='bottom'
                  >
                    <Button
                      onClick={() => setGraphType('line')}
                      size='sm'
                      appearance='tertiary'
                      variant={graphType === 'line' ? 'success' : 'default'}
                      icon={<LineGraphIcon />}
                      active={graphType === 'line'}
                      ariaLabel='line chart'
                    />
                  </Tooltip>
                  <Tooltip
                    tip={t('subscriber_gain.bar_chart_tooltip')}
                    placement='bottom'
                  >
                    <Button
                      onClick={() => setGraphType('bar')}
                      size='sm'
                      appearance='tertiary'
                      variant={graphType === 'bar' ? 'success' : 'default'}
                      active={graphType === 'bar'}
                      icon={<BarGraphIcon />}
                      ariaLabel='bar chart'
                    />
                  </Tooltip>
                  <span style={{ display: 'inline-block', width: '115px' }}>
                    {isFetching ? (
                      <Spinner small centered />
                    ) : (
                      <SingleSelect
                        selectedItem={granularity}
                        data={reportsGranularity}
                        labelKey='label'
                        onChange={value => {
                          actions.updateFiltersAndFetchGrowthReport({
                            granularity: value as ReportsGranularity,
                          });
                        }}
                        name='Granularity'
                        title='Granularity'
                        buttonSize='sm'
                      />
                    )}
                  </span>
                </HStack>
              </HStack>

              <Text size={6} weight={500} variant='primary'>
                <NumberFormat value={totalSubsGained} showPositiveSign />
              </Text>
            </VStack>

            {graphType === 'line' ? (
              <SubscriberLineChart />
            ) : (
              <SubscriberBarChart />
            )}
          </VStack>
        </Pane>

        {/* <Locations /> */}
      </Styled.StatsWrapper>
    </VStack>
  );
};

export default SubscriberStats;
