import { useRouter } from 'next/router';
import { ProgressBar } from 'barn/components/ProgressBar';
import { useTranslation } from 'next-i18next';
import { Pane } from 'barn/components/Pane';
import InfoRoundedIcon from 'barn/icons/InfoRounded';
import { Tooltip } from 'barn/components/Tooltip';
import { Text } from 'barn/components/Text';
import { Stack } from 'barn/components/Stack';
import { Skeleton } from 'src/components/Skeleton';
import { useGlobalState } from 'src/hooks/use-global-state';
import NumberFormat from 'src/components/NumberFormat';
import Hyperlink from 'barn/components/Hyperlink';
import { isMobile } from 'src/lib/notification/util';
import Styled from './styled';

const KeyStats = () => {
  const router = useRouter();

  const {
    state: { summary, isFetching },
  } = useGlobalState('home');
  const {
    state: { consumption },
  } = useGlobalState('settings');
  const { t } = useTranslation('home');
  const { impressionsConsumed = 0, impressionsLimit = 100 } = consumption || {};
  const showUpgradeOption = impressionsConsumed / impressionsLimit >= 0.8;

  return (
    <Styled.StatsWidget>
      <Pane testHandle='home-subscriber-count-card'>
        <Styled.StatCard icon='SubscribersCard'>
          <h3 className='value'>
            {isFetching ? (
              <Skeleton />
            ) : (
              <NumberFormat value={summary.total_subscribers} />
            )}
          </h3>
          <div className='label'>{t('total_subscribers')}</div>
        </Styled.StatCard>
      </Pane>
      <Pane testHandle='home-total-orders'>
        <Styled.StatCard icon='Cart'>
          <h3 className='value'>
            {isFetching ? (
              <Skeleton />
            ) : (
              <NumberFormat value={summary.total_orders} />
            )}
          </h3>
          <div className='label'>{t('total_orders')}</div>
        </Styled.StatCard>
      </Pane>
      <Pane testHandle='home-revenue-card'>
        <Styled.StatCard icon='RevenueGenerated'>
          <h3 className='value'>
            {isFetching ? (
              <Skeleton />
            ) : (
              <NumberFormat
                value={summary.total_revenue_generated}
                isCurrency
              />
            )}
          </h3>
          <div className='label'>{t('total_revenue_generated')}</div>
        </Styled.StatCard>
      </Pane>

      <Pane testHandle='home-impressions-card'>
        <Styled.StatCard icon='Impressions'>
          <h3 className='value mb-8'>
            {consumption.isFetching ? (
              <Skeleton />
            ) : (
              <>
                <NumberFormat value={consumption.impressionsConsumed} />{' '}
                <span className='small'>
                  /
                  <NumberFormat value={consumption.impressionsLimit} />
                </span>
              </>
            )}
          </h3>
          <div className='widget'>
            <ProgressBar
              width={172}
              max={consumption.impressionsLimit}
              value={consumption.impressionsConsumed}
            />
          </div>
          <div className='label'>
            <Stack gap={3} align='center'>
              {/* The following jsx is the same except the placement of tooltip icon. In mobile view to not hide the tooltip it is placed before the text and in desktop view it is placed after the text */}
              {isMobile() ? (
                <>
                  <Tooltip placement='top' tip={t('credits_consumed_tooltip')}>
                    <InfoRoundedIcon size={18} />
                  </Tooltip>
                  <Text size={2} variant='primary'>
                    {t('credits_consumed')}
                  </Text>
                </>
              ) : (
                <>
                  <Text size={2} variant='primary'>
                    {t('credits_consumed')}
                  </Text>
                  <Tooltip tip={t('credits_consumed_tooltip')}>
                    <InfoRoundedIcon size={18} />
                  </Tooltip>
                </>
              )}
            </Stack>
            {showUpgradeOption && (
              <Hyperlink
                variant='button'
                href='/tools/topup'
                testHandle='home-impressions-upgrade-btn'
                onClick={() => router.push('/tools/topup')}
              >
                Top-up
              </Hyperlink>
            )}
          </div>
        </Styled.StatCard>
      </Pane>
    </Styled.StatsWidget>
  );
};

export default KeyStats;
