import React, { useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Bar,
  Legend,
  Tooltip,
  Cell,
} from 'recharts';
import { format } from 'date-fns';
import colors from 'barn/tokens/colors';
import { useGlobalState } from 'src/hooks/use-global-state';
import CustomTooltip from './CustomTooltip';
import CustomLegend from './CustomLegend';

const SubscriberBarChart = () => {
  const [activeIndex, setActiveIndex] = useState<number>(null);

  const {
    state: {
      reports: { subscriberGrowthBreakdown },
    },
  } = useGlobalState('home');

  return (
    <ResponsiveContainer>
      <BarChart data={subscriberGrowthBreakdown || []}>
        <CartesianGrid strokeDasharray='3' vertical={false} />

        <YAxis
          type='number'
          domain={[
            0,
            max => (Number.isFinite(max) ? max + (10 - (max % 10)) : 10),
          ]}
          allowDecimals={false}
          allowDataOverflow
        />

        <XAxis
          interval='preserveStartEnd'
          minTickGap={10}
          dataKey='date'
          tickFormatter={date =>
            date === 'auto'
              ? format(new Date(), 'dd MMM')
              : format(new Date(date), 'dd MMM')
          }
        />

        <Tooltip
          animationEasing='linear'
          cursor={false}
          content={CustomTooltip}
        />

        <Legend content={CustomLegend} />

        <Bar
          dataKey='count'
          name='Subscriber Gains'
          legendType='square'
          onMouseEnter={(_, i) => setActiveIndex(i)}
          onMouseLeave={() => setActiveIndex(null)}
        >
          {subscriberGrowthBreakdown.map((_, index) => (
            <Cell
              fill={
                !activeIndex || index === activeIndex
                  ? colors.greens[4]
                  : colors.greens[8]
              }
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SubscriberBarChart;
