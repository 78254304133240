import styled, { keyframes } from 'styled-components';
import tokens from 'barn/tokens';
import React from 'react';

const anim = ({ theme }) => keyframes`
    50% { background-color: ${theme.skeleton.bg2} }
`;

const StyledSkeleton = styled.div`
  background-color: ${({ theme }) => theme.skeleton.bg1};
  border-radius: ${tokens.radii[3]};
  animation-name: ${anim};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

type Props = {
  width?: string;
  height?: string;
};
export const Skeleton = ({ width = '50%', height = '1em' }: Props) => (
  <StyledSkeleton
    style={{
      height,
      width,
    }}
  />
);
