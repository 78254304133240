import { useTranslation } from 'react-i18next';
import Hyperlink from 'barn/components/Hyperlink';
import { HStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import useAuth from 'src/hooks/use-auth';
import { StepProp } from './index';
import { Screenshot } from '../styled';
import { StepDetail } from './StepDetail';

export const TOS = (props: StepProp) => {
  const {
    user: {
      website: { subdomain },
    },
  } = useAuth();
  const { t } = useTranslation('home');

  const link = `https://${subdomain}.myshopify.com/admin/settings/legal`;

  return (
    <StepDetail
      heading={t('setup_card.terms_of_service.heading')}
      subHeading={
        <HStack gap={2}>
          <Text size={2}>{t('setup_card.go_to')}</Text>
          <Hyperlink href={link}>
            {t('settings')} &gt; {t('setup_card.terms_of_service.title')}
          </Hyperlink>
          <Text size={2}>{t('setup_card.shop_admin')}</Text>
        </HStack>
      }
      link={link}
      primaryButtonText={t('setup_card.terms_of_service.enable')}
      secondaryButtonText={t('setup_card.terms_of_service.dismiss')}
      {...props}
    >
      <Screenshot
        alt='shopify admin privacy policy'
        src='/static/img/tos.png'
      />
    </StepDetail>
  );
};
