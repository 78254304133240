import styled from 'styled-components';
import tokens from 'barn/tokens';
import misc from 'barn/tokens/misc';
import space from 'barn/tokens/space';
import { fontSizes } from 'barn/tokens/typography';

const StatsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 440px;
  grid-column-gap: 16px;
  margin: 0 0 ${tokens.space.margin[7]};

  .home-locations .content {
    overflow-y: auto;
  }
`;

const TooltipLine = styled.figure`
  width: 6px;
  background-color: ${({ theme }) => theme.home.tooltip.color};
  border-radius: ${misc.radii[3]};
`;

const Separator = styled.figure`
  display: inline;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.home.borderColor};
  align-self: center;
`;

const Tooltip = styled.figure`
  background-color: ${({ theme }) => theme.home.tooltip.bg};
  border-radius: ${misc.radii[4]};
  padding: ${space.padding[4]};
  border: 1px solid ${({ theme }) => theme.home.borderColor};
`;

const Legend = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LegendIcon = styled.figure`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.home.tooltip.color};
  border-radius: ${misc.radii[2]};
  display: inline-block;
`;

const Heading = styled.h2`
  padding: 0 0 ${space.padding[3]} ${space.padding[6]};
  border-bottom: 1px solid ${({ theme }) => theme.home.borderColor};
  color: ${({ theme }) => theme.home.bold.color};
  font-size: ${fontSizes[3]};
  font-weight: 600;
`;

const Head = styled.div`
  padding: ${space.padding[3]} ${space.padding[6]};
  border-bottom: 1px solid ${({ theme }) => theme.home.borderColor};
  display: flex;
  justify-content: space-between;
`;

const Location = styled.div`
  padding: ${space.padding[3]} ${space.padding[6]};
  border-bottom: 1px solid ${({ theme }) => theme.home.location.borderColor};
  display: flex;
  justify-content: space-between;
`;

const NoData = styled.span`
  text-align: center;
  padding-top: ${space.padding[5]};
  font-weight: 600;
  color: ${({ theme }) => theme.home.bold.color};
`;

export default {
  StatsWrapper,
  TooltipLine,
  Separator,
  Tooltip,
  Legend,
  LegendIcon,
  Heading,
  Head,
  Location,
  NoData,
};
