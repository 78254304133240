import { useTranslation } from 'react-i18next';
import Hyperlink from 'barn/components/Hyperlink';
import { Text } from 'barn/components/Text';
import useAuth from 'src/hooks/use-auth';
import { StepProp } from './index';
import { Screenshot } from '../styled';
import { StepDetail } from './StepDetail';

export const Privacy = (props: StepProp) => {
  const {
    user: {
      website: { subdomain },
    },
  } = useAuth();
  const { t } = useTranslation('home');
  const link = `https://${subdomain}.myshopify.com/admin/settings/legal`;

  return (
    <StepDetail
      heading={t('setup_card.privacy_policy.title')}
      subHeading={
        <div>
          <Text size={2}>{t('setup_card.go_to')}</Text>{' '}
          <Hyperlink href={link}>
            {t('setup_card.settings')} &gt; {t('setup_card.policy')}
          </Hyperlink>
          <Text size={2}> {t('setup_card.shop_admin')}</Text>
        </div>
      }
      link={link}
      primaryButtonText={t('setup_card.privacy_policy.enable')}
      secondaryButtonText={t('setup_card.privacy_policy.dismiss')}
      {...props}
    >
      <Screenshot
        alt='shopify admin privacy policy'
        src='/static/img/privacy_policy.png'
      />
    </StepDetail>
  );
};
