import React from 'react';
import ReactNumberFormat from 'react-number-format';
import useAuth from 'src/hooks/use-auth';

interface Props {
  displayType?: 'input' | 'text';
  value: number;
  thousandSeparator?: boolean;
  isCurrency?: boolean;
  renderText?: (formattedValue: string) => React.ReactNode;
  fixedDecimalScale?: boolean;
  showPositiveSign?: boolean;
}
const NumberFormat: React.FunctionComponent<Props> = ({
  value,
  displayType = 'text',
  thousandSeparator = true,
  isCurrency = false,
  renderText = null,
  fixedDecimalScale = false,
  showPositiveSign = false,
}) => {
  const { user } = useAuth();

  return (
    <>
      {showPositiveSign && value >= 0 ? '+' : ''}
      <ReactNumberFormat
        value={
          value !== null ? value : 0
        } /* if api response contains `null`, set value to 0 */
        displayType={displayType}
        thousandSeparator={thousandSeparator}
        renderText={
          isCurrency
            ? text => user.currency.replace(user.amountPlaceholderText, text)
            : renderText
        }
        decimalScale={isCurrency && value > 999 ? 0 : 2}
        fixedDecimalScale={fixedDecimalScale}
      />
    </>
  );
};

export default NumberFormat;
