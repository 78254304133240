import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

const StyledTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${tokens.space.margin[8]};
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  * + * {
    margin-left: ${tokens.space.margin[4]};
  }
`;

const StyledTitle = styled.h1`
  font-size: ${fontSizes[5]};
  margin-top: 0;
  font-weight: 600;
  color: ${({ theme }) => theme.layout.bold.color};
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface TitleBarProps {
  title: string;
  cta?: React.ReactNode;
  filter?: React.ReactNode;
}
const TitleBar: React.FunctionComponent<TitleBarProps> = ({
  title,
  cta,
  filter = <div />,
}) => (
  <StyledTitleBar>
    <FlexCenter>
      <StyledTitle>{title}</StyledTitle>
      {filter}
    </FlexCenter>
    <StyledActions>{cta}</StyledActions>
  </StyledTitleBar>
);

export default TitleBar;
