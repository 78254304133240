import { Stack } from 'barn/components/Stack';
import { Pane } from 'barn/components/Pane';
import { useGlobalState } from 'src/hooks/use-global-state';
import { StepIndicator } from './StepIndicator';
import { StepDetails } from './StepDetails';
import Styled from './styled';
import { gettingStartedKeys } from '../models';
import { gettingStartedSteps } from '../constants';

const cardSteps = [
  [
    {
      name: gettingStartedSteps.PRIVACY,
      label: 'setup_card.privacy_policy.title',
    },
    { name: gettingStartedSteps.TOS, label: 'terms_of_service.title' },
  ],
  [
    {
      name: gettingStartedSteps.OPTIN,
      label: 'setup_card.collect_subscribers.title',
    },
    {
      name: gettingStartedSteps.ACR,
      label: 'setup_card.recover_abandoned_carts.title',
    },
  ],
];

type Props = {
  initial: object;
  animate: object;
};

export const GettingStartedProgress = ({
  motionVariants,
}: {
  motionVariants: Props;
}) => {
  const {
    state: {
      gettingStartedStep,
      completedGettingStartedStep,
      isFetchingGettingStarted,
    },
  } = useGlobalState('home');

  if (
    gettingStartedKeys.length + 1 === gettingStartedStep ||
    isFetchingGettingStarted
  ) {
    return null;
  }

  const isFirstCard = completedGettingStartedStep + 1 < cardSteps[0].length;

  const cardIndex = isFirstCard ? 0 : 1;
  const cardOffset = isFirstCard ? 0 : cardSteps[0].length;

  return (
    <Styled.GettingStartedProgress
      {...motionVariants}
      data-test='gettingStarted'
    >
      <Pane>
        <Stack direction='responsive' gap={9}>
          <div style={{ flexShrink: 0, minWidth: '280px' }}>
            <StepIndicator
              steps={cardSteps[cardIndex]}
              cardOffset={cardOffset}
            />
          </div>
          <Styled.ResponsiveSeparator />
          <StepDetails />
        </Stack>
      </Pane>
    </Styled.GettingStartedProgress>
  );
};
