import { useTranslation } from 'react-i18next';
import { Text } from 'barn/components/Text';
import { StepDetail } from './StepDetail';

type Props = {
  index: number;
};

export const ACR = ({ index }: Props) => {
  const link = '/automation/abandoned-cart-recovery';
  const { t } = useTranslation('home');

  return (
    <StepDetail
      heading={t('setup_card.recover_abandoned_carts.title')}
      index={index}
      subHeading={t('setup_card.recover_abandoned_carts.subheading')}
      link={link}
      primaryButtonText={t('setup_card.recover_abandoned_carts.title')}
      openLinkInNewTab={false}
    >
      <Text size={2} maxWidth='70ch'>
        {t('setup_card.recover_abandoned_carts.description')}
      </Text>
    </StepDetail>
  );
};
