import SaveToast from 'barn/components/Toast/Save';
import tokens from 'barn/tokens';
import { useGlobalState } from 'src/hooks/use-global-state';
import Pricing from 'src/modules/pricing/views';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import PromotionPopups from 'src/modules/promotion-popups/views';
import { Banner } from 'src/components/Banners';
import { motionVariants } from 'src/modules/home/views';
import { useShowPricing } from 'src/hooks/use-show-pricing';
import Wrapper from './Wrapper';
import TitleBar from './TitleBar';
import Content from './Content';
import Stats from './Stats';

const DefaultLayout = ({ children }) => {
  const { state: toastState } = useGlobalState('saveToast');
  useShowPricing();

  return (
    <>
      <Wrapper>
        <ErrorBoundary>
          <Banner
            motionVariants={motionVariants}
            style={{ marginTop: tokens.space.margin[7] }}
          />
          {children}
        </ErrorBoundary>
      </Wrapper>
      <SaveToast
        isLoading={toastState.isLoading}
        hidden={toastState.hidden}
        type={toastState.type}
      >
        {toastState.message}
      </SaveToast>
      <Pricing />
      <PromotionPopups />
    </>
  );
};

DefaultLayout.TitleBar = TitleBar;
DefaultLayout.Content = Content;
DefaultLayout.Stats = Stats;

export default DefaultLayout;
