import { useState } from 'react';
import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LineChart,
  Line,
} from 'recharts';
import { format } from 'date-fns';
import colors from 'barn/tokens/colors';
import { useGlobalState } from 'src/hooks/use-global-state';
import CustomTooltip from './CustomTooltip';
import CustomLegend from './CustomLegend';

const SubscriberLineChart = () => {
  const [isChartActive, setIsChartActive] = useState<boolean>(false);

  const {
    state: {
      reports: { subscriberGrowthBreakdown },
    },
  } = useGlobalState('home');

  return (
    <ResponsiveContainer>
      <LineChart
        data={subscriberGrowthBreakdown || []}
        onMouseMove={() => setIsChartActive(true)}
        onMouseLeave={() => setIsChartActive(false)}
      >
        <CartesianGrid strokeDasharray='3' vertical={false} />

        <YAxis
          type='number'
          domain={[
            0,
            max => (Number.isFinite(max) ? max + (10 - (max % 10)) : 10),
          ]}
          allowDecimals={false}
          allowDataOverflow
        />

        <XAxis
          padding={{ right: 5 }}
          interval='preserveStartEnd'
          minTickGap={10}
          dataKey='date'
          tickFormatter={date =>
            date === 'auto' ? null : format(new Date(date), 'dd MMM')
          }
        />

        <Tooltip
          animationEasing='linear'
          cursor={false}
          content={CustomTooltip}
        />

        <Legend content={CustomLegend} />

        <Line
          activeDot={{ fill: colors.greens[4], r: 8 }}
          dot={false}
          name='Subscriber Gains'
          dataKey='count'
          stroke={isChartActive ? colors.greens[8] : colors.greens[4]}
          strokeWidth={4}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SubscriberLineChart;
