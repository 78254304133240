import { useTranslation } from 'react-i18next';
import { VStack } from 'barn/components/Stack';
import { Text } from 'barn/components/Text';
import { useGlobalState } from 'src/hooks/use-global-state';
import { gettingStartedSteps } from '../constants';
import Styled from './styled';

type Props = {
  cardOffset: number;
  steps: Array<{
    name: gettingStartedSteps;
    label: string;
  }>;
};

export const StepIndicator = ({ cardOffset, steps }: Props) => {
  const {
    state: { gettingStartedStep },
  } = useGlobalState('home');
  const { t } = useTranslation('home');

  return (
    <VStack gap={5}>
      <VStack gap={2}>
        <Text tag='h3' size={5} weight={700} variant='primary'>
          {t('setup_card.title')}
        </Text>
        <Text tag='p' size={2}>
          {t('setup_card.description')}
        </Text>
      </VStack>

      <VStack gap={2}>
        <Styled.OrderedList>
          {steps.map((step, index) => {
            const isActive = gettingStartedStep === index + cardOffset;

            return (
              <Styled.OrderedListItem key={step.name} isActive={isActive}>
                <Text size={2} weight={isActive ? 700 : 'normal'}>
                  {t(step.label)}
                </Text>
              </Styled.OrderedListItem>
            );
          })}
        </Styled.OrderedList>
      </VStack>
    </VStack>
  );
};
