import styled, { DefaultTheme } from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';
import { capitalize } from 'src/lib/utils';

const StatsWidget = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: ${tokens.space.margin[5]};
  grid-row-gap: ${tokens.space.margin[5]};
  margin: ${tokens.space.margin[9]} 0 ${tokens.space.margin[7]};
`;

interface StatCardProps {
  theme: DefaultTheme;
  icon:
    | 'CampaignsSent'
    | 'Impressions'
    | 'RevenueGenerated'
    | 'SubscribersCard'
    | 'Cart';
}

// FIXME: use single image for icon
const getIconFilename = (props: StatCardProps) =>
  `${props.icon}${capitalize(props.theme.themeName)}`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  background: url('/static/svg/Indicator/${getIconFilename}.svg') no-repeat;
  background-position: top 12px right 12px;
  width: 100%;
  height: 100%;

  > .value {
    margin: 0 0 ${tokens.space.margin[6]} 0;
    font-size: ${fontSizes[5]};
    font-weight: 600;
    color: ${({ theme }) => theme.home.bold.color};

    &.mb-8 {
      margin-bottom: ${tokens.space.margin[3]};
    }

    span.small {
      font-size: ${fontSizes[2]};
      color: ${({ theme }) => theme.home.small.color};
    }
  }

  > .widget {
    margin-bottom: ${tokens.space.margin[3]};
  }

  > .label {
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.home.label.color};
    font-size: ${fontSizes[2]};
  }
`;

export default {
  StatsWidget,
  StatCard,
};
